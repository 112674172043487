@import './swiper-bundle.css';
@import './fonts.css';
@import './cookie-banner.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    overflow: auto;
  }
  body {
    overflow-x: hidden;
    @apply text-blue100 text-body;
  }
  h1 {
    @apply text-h1;
  }
  h2 {
    @apply text-h2;
  }
  h3 {
    @apply text-h3;
  }
  h4 {
    @apply text-h4;
  }
  h5 {
    @apply text-h5;
  }
  h6 {
    @apply text-body-lg;
  }
  button {
    @apply text-button;
  }

  .underline-headline-white {
    @apply relative mb-4 pb-3 after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-6 after:bg-white after:content-[''] md:mb-6;
  }
  .underline-headline-blue {
    @apply relative mb-4 pb-3 after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-6 after:bg-blue100 after:content-[''] md:mb-6;
  }
  .blueLight {
    background: linear-gradient(145.01deg, #78d6cc 23.99%, #b2ede8 75.55%);
  }
  .pinkBlue {
    background: linear-gradient(145.41deg, #ffd9d9 23.97%, #b2ede8 73.63%);
  }
  .yellowBlue {
    background: linear-gradient(214.78deg, #b2ede8 20.86%, #ffefd2 82.61%);
  }
  .lightPink {
    background: linear-gradient(330.95deg, #f0939e 20.73%, #ffd9d9 80.11%);
  }
  .yellowPink {
    background: linear-gradient(216.53deg, #ffefd2 26.62%, #ffd9d9 73.72%);
  }
  .orangeRed {
    background: linear-gradient(35.94deg, #ea4e73 16.4%, #ffa300 80.32%);
  }
  .yellowLight {
    background: linear-gradient(323.13deg, #ffefd2 22.69%, #fdc664 78.71%);
  }
  .blueTransparent70 {
    background: rgba(0, 0, 69, 0.7);
  }
  .clipImage {
    max-height: 500px;
    clip-path: polygon(0 16%, 70% 0, 100% 11%, 100% 83%, 31% 100%, 0 89%);
  }
  .clipAvatar {
    aspect-ratio: cos(30deg);
    clip-path: polygon(-50% 50%, 50% 100%, 150% 50%, 50% 0);
  }
  .clipBg {
    clip-path: polygon(0 10%, 71% 0, 100% 6%, 100% 89%, 31% 100%, 0% 94%);
  }
  .clipLogo {
    clip-path: polygon(5% 0, 100% 1%, 100% 88%, 95% 100%, 0 100%, 0 14%);
  }
  .clipPortfolio {
    clip-path: polygon(99% 0, 100% 81%, 65% 100%, 0 100%, 0 0);
  }
  .clipPortfolioMobile {
    clip-path: polygon(0 0, 100% 0, 100% 58%, 38% 100%, 0 74%);
  }
  .dropShadowSmall {
    box-shadow:
      0px 4px 6px 0px #0000450d,
      0px 10px 15px 0px #0000451a;
  }
  .dropShadowBig {
    filter: drop-shadow(0px 20px 25px rgba(0, 0, 87, 0.1))
      drop-shadow(0px 10px 10px rgba(0, 0, 87, 0.04));
  }
  .dropShadowNone {
    box-shadow: none;
  }
  .textGradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .svgWrapper > svg {
    @apply h-full w-full;
  }
}

@layer components {
  .contentStyles p {
    @apply mb-4;
  }

  .contentStyles ul {
    @apply mb-4 pl-10 md:mb-6;
    list-style-type: '\203A\203A';
  }

  .contentStyles ol {
    @apply mb-4 pl-10 md:mb-6;
    list-style-type: decimal-leading-zero;
  }

  .contentStyles a {
    @apply underline hover:text-turquoise70;
    font-size: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    text-transform: inherit;
  }

  .contentStyles li {
    @apply px-2;
  }

  .contentStyles h1,
  .contentStyles h2,
  .contentStyles h3,
  .contentStyles h4,
  .contentStyles h5,
  .contentStyles h6 {
    @apply mb-4 md:mb-6;
  }
  .contentStyles.column-element img {
    @apply block h-[100px] w-[100px] object-contain;
  }
  .contentStyles.column-element.wideimages img {
    @apply block h-[200px] w-full object-contain;
  }

  .contentStyles.text-center img {
    @apply mx-auto !important;
  }

  .contentStyles li::marker {
    @apply text-yellow70;
  }
  .contentStyles em {
    font-family: inherit;
    @apply not-italic text-yellow70;
  }

  .contentStyles > *:last-child {
    @apply mb-0;
  }

  .tonnenheldImageTextBannerContentStyles h2 {
    @apply text-white;
  }

  .tonnenheldImageTextBannerContentStyles p {
    @apply font-bold text-[#05204A];
  }

  .tonnenheldThreeColumnContentStyles p {
    @apply text-center text-lg font-medium md:mb-6 md:text-left md:text-xl;
  }

  .tonnenheldThreeColumnContentStyles p img {
    @apply md:!mx-0;
  }

  .tonnenheldSubmitButtonContentStyles a {
    @apply text-[#4FB0AD];
  }

  .svgWrapper > svg {
    @apply h-full w-full;
  }

  .image-container > span {
    @apply position-unset !important;
  }

  .image-container .image {
    @apply relative h-unset w-full object-contain !important;
  }
  [data-slate-editor] {
    -webkit-user-modify: read-write !important;
  }

  .contentStyles table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }

  .contentStyles th,
  .contentStyles td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  .contentStyles th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
}
