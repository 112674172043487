@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Medium.ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
